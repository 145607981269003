// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  pagination_limit: 10,
  pagination_size: 5,
  marker_icon: {
    scaledSize: {height:40, width:40},
    url: 'assets/img/bicycle.png'
  },
  default_map: {
      lat: 12.6496222,
      lng: 104.3004339,
      zoom: 14
  },
  center_map: {
      lat: 48.8566969,
      lng: 2.3514616,
      zoom: 13
  },
  languages: [
      {
          name: 'French',
          code: 'fr'
      },
      {
          name: 'English',
          code: 'en'
      }
  ],
  language_default: 'fr',
  logo: 'assets/img/Logo.png',
  no_avatar: 'assets/img/no_avatar.png',
  logo_default: 'assets/img/Logo.png',
  logo_company_default: 'assets/img/logoDFM1.png',
  no_logo_company: 'assets/img/no-image-icon.png',
  urlCompany: 'https://vtrack.geosmartone.com/storage/companies/'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
