export const params = {
    marker_icon: {
        scaledSize: {height:40, width:40},
        url: 'assets/img/bicycle.png'
    },
    default_map: {
        lat: 12.6496222,
        lng: 104.3004339,
        zoom: 14
    },
    center_map: {
        lat: 48.8566969,
        lng: 2.3514616,
        zoom: 13
    },
    languages: [
        {
            name: 'French',
            code: 'fr'
        },
        {
            name: 'English',
            code: 'en'
        }
    ],
    language_default: 'fr',
    logo: 'assets/img/Logo.png',
    no_avatar: 'assets/img/no_avatar.png',
    logo_default: 'assets/img/Logo.png',
    logo_company_default: 'assets/img/logoDFM1.png',
    no_logo_company: 'assets/img/no-image-icon.png',
    api: 'https://vtrack.geosmartone.com/api/v1',
    urlCompany: 'https://vtrack.geosmartone.com/storage/companies/'
    // api: 'http://localhost:8000/api/v1',
    // urlCompany: 'http://localhost:8000/storage/companies/'
};