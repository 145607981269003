/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse, 
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { filter } from 'rxjs/operators/filter';

import { Device } from '../models/device';
import { idArr } from '../models/id-arr';
import { DeviceSearch } from '../models/device-search';
import { Import } from '../models/import';
import { DeviceHistory } from '../models/device-history';
import { User } from '../models/user';


@Injectable()
export class AdminDevicesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param page - The number of items to skip before starting to collect the result set.
   * @param limit - The numbers of items to return.
   */
  adminListDevicesResponse(params: AdminDevicesService.AdminListDevicesParams): Observable<HttpResponse<Device[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.page != null) __params = __params.set("page", params.page.toString());
    if (params.limit != null) __params = __params.set("limit", params.limit.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/admin/device`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Device[] = null;
        _body = _resp.body as Device[]
        return _resp.clone({body: _body}) as HttpResponse<Device[]>;
      })
    );
  }

  /**
   * @param page - The number of items to skip before starting to collect the result set.
   * @param limit - The numbers of items to return.
   */
  adminListDevices(params: AdminDevicesService.AdminListDevicesParams): Observable<Device[]> {
    return this.adminListDevicesResponse(params).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param body - Created Device object
   */
  adminCreateDeviceResponse(body?: Device): Observable<HttpResponse<Device>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/admin/device`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Device = null;
        _body = _resp.body as Device
        return _resp.clone({body: _body}) as HttpResponse<Device>;
      })
    );
  }

  /**
   * @param body - Created Device object
   */
  adminCreateDevice(body?: Device): Observable<Device> {
    return this.adminCreateDeviceResponse(body).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param body - update
   */
  adminUpdateDeviceResponse(body?: Device): Observable<HttpResponse<Device>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "PATCH",
      this.rootUrl + `/admin/device`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Device = null;
        _body = _resp.body as Device
        return _resp.clone({body: _body}) as HttpResponse<Device>;
      })
    );
  }

  /**
   * @param body - update
   */
  adminUpdateDevice(body?: Device): Observable<Device> {
    return this.adminUpdateDeviceResponse(body).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param deviceId - undefined
   */
  adminGetDeviceResponse(deviceId: number): Observable<HttpResponse<Device>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/admin/device/${deviceId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Device = null;
        _body = _resp.body as Device
        return _resp.clone({body: _body}) as HttpResponse<Device>;
      })
    );
  }

  /**
   * @param deviceId - undefined
   */
  adminGetDevice(deviceId: number): Observable<Device> {
    return this.adminGetDeviceResponse(deviceId).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param deviceId - undefined
   */
  adminDeleteDeviceResponse(deviceId: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/admin/device/${deviceId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;
        
        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param deviceId - undefined
   */
  adminDeleteDevice(deviceId: number): Observable<void> {
    return this.adminDeleteDeviceResponse(deviceId).pipe(
      map(_r => _r.body)
    );
  }
  /**
   */
  adminDeviceAllResponse(): Observable<HttpResponse<Device[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/admin/device/all`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Device[] = null;
        _body = _resp.body as Device[]
        return _resp.clone({body: _body}) as HttpResponse<Device[]>;
      })
    );
  }

  /**
   */
  adminDeviceAll(): Observable<Device[]> {
    return this.adminDeviceAllResponse().pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param body - Created Device object
   */
  adminDeviceExistResponse(body?: Device): Observable<HttpResponse<Device>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/admin/device/exist`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Device = null;
        _body = _resp.body as Device
        return _resp.clone({body: _body}) as HttpResponse<Device>;
      })
    );
  }

  /**
   * @param body - Created Device object
   */
  adminDeviceExist(body?: Device): Observable<Device> {
    return this.adminDeviceExistResponse(body).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param body - DeleteAll Device object
   */
  adminDeleteAllDeviceResponse(body?: idArr): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/admin/device/deleteall`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;
        
        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param body - DeleteAll Device object
   */
  adminDeleteAllDevice(body?: idArr): Observable<void> {
    return this.adminDeleteAllDeviceResponse(body).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param body - Search Device object
   */
  adminDeviceSearchResponse(body?: DeviceSearch): Observable<HttpResponse<Device[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/admin/device/search`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Device[] = null;
        _body = _resp.body as Device[]
        return _resp.clone({body: _body}) as HttpResponse<Device[]>;
      })
    );
  }

  /**
   * @param body - Search Device object
   */
  adminDeviceSearch(body?: DeviceSearch): Observable<Device[]> {
    return this.adminDeviceSearchResponse(body).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param body - Import Device object
   */
  adminDeviceImportResponse(body?: Import): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/admin/device/import`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;
        
        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param body - Import Device object
   */
  adminDeviceImport(body?: Import): Observable<void> {
    return this.adminDeviceImportResponse(body).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param body - Device History object
   */
  adminGetDeviceHistoryResponse(body?: DeviceHistory): Observable<HttpResponse<DeviceHistory[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/admin/device/history`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: DeviceHistory[] = null;
        _body = _resp.body as DeviceHistory[]
        return _resp.clone({body: _body}) as HttpResponse<DeviceHistory[]>;
      })
    );
  }

  /**
   * @param body - Device History object
   */
  adminGetDeviceHistory(body?: DeviceHistory): Observable<DeviceHistory[]> {
    return this.adminGetDeviceHistoryResponse(body).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param body - Import Device object
   */
  adminDeviceNotUsedResponse(body?: User): Observable<HttpResponse<Device[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/admin/device/notUsed`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Device[] = null;
        _body = _resp.body as Device[]
        return _resp.clone({body: _body}) as HttpResponse<Device[]>;
      })
    );
  }

  /**
   * @param body - Import Device object
   */
  adminDeviceNotUsed(body?: User): Observable<Device[]> {
    return this.adminDeviceNotUsedResponse(body).pipe(
      map(_r => _r.body)
    );
  }}

export module AdminDevicesService {
  export interface AdminListDevicesParams {
    page?: number;
    limit?: number;
  }
}
