import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../api/services';
import { User } from '../../api/models';

@Component({
  selector: 'app-signout',
  templateUrl: './signout.component.html',
  styleUrls: ['./signout.component.css']
})
export class SignoutComponent implements OnInit, AfterViewInit {

  public user: User = null;

  constructor(private apiAuths: AuthService, private router: Router) {}

  ngOnInit() {
  }

  ngAfterViewInit() {
    // localStorage.removeItem('token');
    // localStorage.removeItem('user');
    // localStorage.removeItem('role');
    // localStorage.clear();
    // this.router.navigate(['/auth/signin']);
    this.user = JSON.parse(localStorage.getItem('user'));
    this.apiAuths.logout({
      user_id: this.user.id,
      token: localStorage.getItem('token')
    }).subscribe(
      data => {

        // check old data
        if (localStorage.getItem('token_shadow')) {

          // set data new
          const token = localStorage.getItem('token_shadow');
          const user = localStorage.getItem('user_shadow');
          const role = localStorage.getItem('role_shadow');
          const permissions = localStorage.getItem('permissions_shadow');
          localStorage.setItem('token', token);
          localStorage.setItem('user', user);
          localStorage.setItem('role', role);
          localStorage.setItem('permissions', permissions);

          // remove data
          localStorage.removeItem('token_shadow');
          localStorage.removeItem('user_shadow');
          localStorage.removeItem('role_shadow');
          localStorage.removeItem('permissions_shadow');
          this.router.navigate(['/admin']);
          return;
        }
        let language = localStorage.getItem('language');
        localStorage.clear();
        localStorage.setItem('language', language);
        this.router.navigate(['/auth/signin']);
      }
    );
  }
}
