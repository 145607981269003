import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../api/services';
import { User, Login } from '../../api/models';
import swal from 'sweetalert2';
//import { RolesService } from '../../shared/roles.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit, AfterViewInit {

  public login: Login;
  public token = null;
  public user: User = null;

  constructor(
    private translate: TranslateService,
    //private roles: RolesService, 
    private apiAuths: AuthService, 
    private router: Router) {
    this.login = new Login();
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (localStorage.getItem('token')) {

      if (localStorage.getItem('role') === 'admin') {
        this.router.navigate(['/admin']);
      } else {
        this.router.navigate(['/manager']);
      }
    }
  }

  onLogin() {
    this.apiAuths.login({
      email: this.login.email,
      password: this.login.password
    }).subscribe(
      res => {
        let language = localStorage.getItem('language');
        if (language) {
          this.translate.use(language);
        } else {
          localStorage.setItem('language', 'fr');
          this.translate.use('fr');
        }
        
        localStorage.setItem('token', res.token);
        localStorage.setItem('user', JSON.stringify(res.user));
        //this.roles.setPermissions(res.user.permissions);
        const roleName = res.user.role.name;
        localStorage.setItem('role', roleName);
        if (roleName === 'admin') {
          this.router.navigate(['/admin']);
        } else {
          this.router.navigate(['/manager']);
        }
      },
      err => {
        swal('Warning', 'Logged in failed', 'error');
      }
    );
  }
}
