/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse, 
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { filter } from 'rxjs/operators/filter';

import { Device } from '../models/device';
import { DeviceInform } from '../models/device-inform';
import { SendDownlink } from '../models/send-downlink';


@Injectable()
export class PublicDevicesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param body - Inform Device
   */
  managerDeviceInformResponse(body?: DeviceInform): Observable<HttpResponse<Device>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/manager/device/inform`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Device = null;
        _body = _resp.body as Device
        return _resp.clone({body: _body}) as HttpResponse<Device>;
      })
    );
  }

  /**
   * @param body - Inform Device
   */
  managerDeviceInform(body?: DeviceInform): Observable<Device> {
    return this.managerDeviceInformResponse(body).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param body - SendDownlink Device
   */
  managerSendDownlinkResponse(body?: SendDownlink): Observable<HttpResponse<Device>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/manager/device/sendDownlink`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Device = null;
        _body = _resp.body as Device
        return _resp.clone({body: _body}) as HttpResponse<Device>;
      })
    );
  }

  /**
   * @param body - SendDownlink Device
   */
  managerSendDownlink(body?: SendDownlink): Observable<Device> {
    return this.managerSendDownlinkResponse(body).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param body - Inform Device
   */
  managerDeviceIfacResponse(body?: DeviceInform): Observable<HttpResponse<Device>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/manager/device/ifac`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Device = null;
        _body = _resp.body as Device
        return _resp.clone({body: _body}) as HttpResponse<Device>;
      })
    );
  }

  /**
   * @param body - Inform Device
   */
  managerDeviceIfac(body?: DeviceInform): Observable<Device> {
    return this.managerDeviceIfacResponse(body).pipe(
      map(_r => _r.body)
    );
  }}

export module PublicDevicesService {
}
