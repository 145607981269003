/* tslint:disable */
import { Company } from './company';

/**
 */
export class Setting {
    id?: number;
    key?: string;
    value?: number;
    company_id?: number;
    company?: Company;
}
