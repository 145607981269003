/* tslint:disable */
import { Role } from './role';
import { Company } from './company';
import { Device } from './device';
import { Permission } from './permission';

/**
 */
export class User {
    id?: number;
    role_id?: number;
    role?: Role;
    company_id?: number;
    company?: Company;
    device_id?: number;
    device?: Device;
    permission?: Permission;
    email?: string;
    first_name?: string;
    last_name?: string;
    address?: string;
    postal_code?: string;
    city?: string;
    register_code?: string;
    last_connection?: string;
    page?: number;
    limit?: number;
}
