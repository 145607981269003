/* tslint:disable */

/**
 */
export class DeviceSearch {
    status_id?: number;
    tracking?: number;
    page?: number;
    limit?: number;
}
