import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.css']
})
export class ResetComponent implements OnInit {

  public status: any;
  public massager: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.status = this.route.snapshot.paramMap.get('type');
    if (this.status == 4 || this.status == 5) {
      this.massager = this.translate.instant('LBL_REFUSED_SUCCESS');
    }
    if (this.status == 3 ) {
      this.massager = this.translate.instant('LBL_LINK_EXPIRED');
    }
    if (this.status == 2 ) {
      this.massager = this.translate.instant('LBL_CHANGE_PASS_ERROR');
    }
    if (this.status == 1 ) {
      this.massager = this.translate.instant('LBL_CHANGE_PASS_SUCCESS');
    }
    
  }

}