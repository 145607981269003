import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { AdminDashboardService } from '../../api/services';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.css']
})
export class AppHeaderComponent implements OnInit, AfterViewInit {

  public user: any = null;
  public avatar: any;
  public pathImage: any;
  public count_alert: any;
  public alerts: any[] = [];
  public count_device: any;
  public role_type: any = 0;

  constructor(
    private apiDashboard: AdminDashboardService
    ) {
  }

  ngOnInit() {
    let domain = location.protocol+'//'+location.hostname+(location.port ? ':'+location.port : '')+'/storage/companies/';
    this.user = JSON.parse(localStorage.getItem('user'));
    if (!this.user.company) {
      this.pathImage = environment.logo_company_default;
    } else {
      if (!this.user.company.logo) {
        this.pathImage = environment.no_logo_company;
      } else {
        this.pathImage = domain + this.user.company.logo;
      }
    }
    this.avatar = environment.no_avatar;
  }

  ngAfterViewInit() {
    this.getDeviceStatus();
  }

  getDeviceStatus() {
    this.apiDashboard.adminDeviceStatus().subscribe(
      res => {
        this.count_device = res.count_all;
        this.count_alert = res.count_alert.length;
        this.alerts = res.count_alert;
      }
    );
  }
  
}
