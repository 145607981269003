/* tslint:disable */
import { User } from './user';
import { Company } from './company';
import { Status } from './status';
import { Mode } from './mode';
import { DeviceHistory } from './device-history';

/**
 */
export class Device {
    id?: number;
    user?: User;
    group_id?: number;
    company_id?: number;
    company?: Company;
    profile_id?: number;
    status_id?: number;
    status?: Status;
    name?: string;
    deve_ui?: string;
    appe_ui?: string;
    app_key?: string;
    last_connection?: string;
    lat?: number;
    lng?: number;
    battery?: number;
    mode?: number;
    mode_data?: Mode;
    num_alert?: number;
    alert?: DeviceHistory;
    acc?: number;
    gps_timeout?: number;
    created_at?: string;
    updated_at?: string;
    last_uplink?: string;
    device_objenious_id?: string;
}
