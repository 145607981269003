/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse, 
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { filter } from 'rxjs/operators/filter';

import { Mode } from '../models/mode';


@Injectable()
export class AdminModesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param page - The number of items to skip before starting to collect the result set.
   * @param limit - The numbers of items to return.
   */
  listModesResponse(params: AdminModesService.ListModesParams): Observable<HttpResponse<Mode[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.page != null) __params = __params.set("page", params.page.toString());
    if (params.limit != null) __params = __params.set("limit", params.limit.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/admin/mode`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Mode[] = null;
        _body = _resp.body as Mode[]
        return _resp.clone({body: _body}) as HttpResponse<Mode[]>;
      })
    );
  }

  /**
   * @param page - The number of items to skip before starting to collect the result set.
   * @param limit - The numbers of items to return.
   */
  listModes(params: AdminModesService.ListModesParams): Observable<Mode[]> {
    return this.listModesResponse(params).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param body - undefined
   */
  createModeResponse(body?: Mode): Observable<HttpResponse<Mode>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/admin/mode`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Mode = null;
        _body = _resp.body as Mode
        return _resp.clone({body: _body}) as HttpResponse<Mode>;
      })
    );
  }

  /**
   * @param body - undefined
   */
  createMode(body?: Mode): Observable<Mode> {
    return this.createModeResponse(body).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param body - undefined
   */
  updateModeResponse(body?: Mode): Observable<HttpResponse<Mode>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "PATCH",
      this.rootUrl + `/admin/mode`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Mode = null;
        _body = _resp.body as Mode
        return _resp.clone({body: _body}) as HttpResponse<Mode>;
      })
    );
  }

  /**
   * @param body - undefined
   */
  updateMode(body?: Mode): Observable<Mode> {
    return this.updateModeResponse(body).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param modeId - undefined
   */
  getModesByIdResponse(modeId: number): Observable<HttpResponse<Mode>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/admin/mode/${modeId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Mode = null;
        _body = _resp.body as Mode
        return _resp.clone({body: _body}) as HttpResponse<Mode>;
      })
    );
  }

  /**
   * @param modeId - undefined
   */
  getModesById(modeId: number): Observable<Mode> {
    return this.getModesByIdResponse(modeId).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param modeId - undefined
   */
  deleteModeResponse(modeId: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/admin/mode/${modeId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;
        
        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param modeId - undefined
   */
  deleteMode(modeId: number): Observable<void> {
    return this.deleteModeResponse(modeId).pipe(
      map(_r => _r.body)
    );
  }
  /**
   */
  listModesAllResponse(): Observable<HttpResponse<Mode[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/admin/mode/all`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Mode[] = null;
        _body = _resp.body as Mode[]
        return _resp.clone({body: _body}) as HttpResponse<Mode[]>;
      })
    );
  }

  /**
   */
  listModesAll(): Observable<Mode[]> {
    return this.listModesAllResponse().pipe(
      map(_r => _r.body)
    );
  }}

export module AdminModesService {
  export interface ListModesParams {
    page?: number;
    limit?: number;
  }
}
