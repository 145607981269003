/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse, 
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { filter } from 'rxjs/operators/filter';

import { Provision } from '../models/provision';


@Injectable()
export class ManagerProvisionsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param userId - undefined
   */
  managerAppProvisionByUserIdResponse(userId: number): Observable<HttpResponse<Provision>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/manager/app/provision/${userId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Provision = null;
        _body = _resp.body as Provision
        return _resp.clone({body: _body}) as HttpResponse<Provision>;
      })
    );
  }

  /**
   * @param userId - undefined
   */
  managerAppProvisionByUserId(userId: number): Observable<Provision> {
    return this.managerAppProvisionByUserIdResponse(userId).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param body - provision Provision object
   */
  managerAppProvisionCreateResponse(body?: Provision): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/manager/app/provision`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;
        
        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param body - provision Provision object
   */
  managerAppProvisionCreate(body?: Provision): Observable<void> {
    return this.managerAppProvisionCreateResponse(body).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param body - provision Provision object
   */
  managerAppProvisionUpdateResponse(body?: Provision): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "PATCH",
      this.rootUrl + `/manager/app/provision`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;
        
        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param body - provision Provision object
   */
  managerAppProvisionUpdate(body?: Provision): Observable<void> {
    return this.managerAppProvisionUpdateResponse(body).pipe(
      map(_r => _r.body)
    );
  }}

export module ManagerProvisionsService {
}
