/* tslint:disable */

/**
 */
export class Company {
    id?: number;
    name?: string;
    address?: string;
    tax_code?: string;
    phone?: string;
    logo?: string;
    extention?: string;
    image?: string;
    email?: string;
    appkey?: string;
    password?: string;
    confirm_password?: string;
    created_at?: string;
    updated_at?: string;
}
