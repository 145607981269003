/* tslint:disable */
import { Company } from './company';
import { User } from './user';

/**
 */
export class Provision {
    id?: number;
    accept?: number;
    receive_mail?: number;
    refuse?: number;
    company_id?: number;
    company?: Company;
    user_id?: number;
    user?: User;
}
