import { Component, OnInit } from '@angular/core';
import { params } from '../../../environments/param';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.css']
})
export class AdminLayoutComponent implements OnInit {

  public user: any = null;
  public avatar: any;
  public current_lang: any = params.language_default;
  public languages: any[] = [];
  public logo_web: any;

  constructor() { }

  ngOnInit() {
    this.getLanguage();
    this.user = JSON.parse(localStorage.getItem('user'));
    this.avatar = params.no_avatar;
    this.logo_web = params.logo;
    this.languages = params.languages.filter(item => item.code != this.current_lang);
  }

  getLanguage() {
    let lang = localStorage.getItem('language');
    if (lang) 
      this.current_lang = lang;
  }

  changeLanguage(lang) {
    localStorage.setItem('language', lang);
    window.location.reload(true);
  }

}
