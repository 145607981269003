/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse, 
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { filter } from 'rxjs/operators/filter';

import { Device } from '../models/device';
import { Dashboard } from '../models/dashboard';
import { DeviceStatus } from '../models/device-status';


@Injectable()
export class AdminDashboardService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param body - Search Device object
   */
  adminDashboardsResponse(body?: Dashboard): Observable<HttpResponse<Device[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/admin/dashboard`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Device[] = null;
        _body = _resp.body as Device[]
        return _resp.clone({body: _body}) as HttpResponse<Device[]>;
      })
    );
  }

  /**
   * @param body - Search Device object
   */
  adminDashboards(body?: Dashboard): Observable<Device[]> {
    return this.adminDashboardsResponse(body).pipe(
      map(_r => _r.body)
    );
  }
  /**
   */
  adminDeviceStatusResponse(): Observable<HttpResponse<DeviceStatus>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/admin/device/status`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: DeviceStatus = null;
        _body = _resp.body as DeviceStatus
        return _resp.clone({body: _body}) as HttpResponse<DeviceStatus>;
      })
    );
  }

  /**
   */
  adminDeviceStatus(): Observable<DeviceStatus> {
    return this.adminDeviceStatusResponse().pipe(
      map(_r => _r.body)
    );
  }}

export module AdminDashboardService {
}
