/* tslint:disable */
import { Status } from './status';

/**
 */
export class DeviceHistory {
    _id?: string;
    device_id?: number;
    lat?: number;
    lng?: number;
    battery?: number;
    mode?: number;
    acc?: number;
    num_alert?: number;
    gps_timeout?: number;
    created_at?: string;
    last_uplink?: string;
    status?: Status;
    name?: string;
    page?: number;
    limit?: number;
}
