/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse, 
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { filter } from 'rxjs/operators/filter';

import { Setting } from '../models/setting';


@Injectable()
export class ManagerSettingsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   */
  managerListSettingsResponse(): Observable<HttpResponse<Setting[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/manager/setting`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Setting[] = null;
        _body = _resp.body as Setting[]
        return _resp.clone({body: _body}) as HttpResponse<Setting[]>;
      })
    );
  }

  /**
   */
  managerListSettings(): Observable<Setting[]> {
    return this.managerListSettingsResponse().pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param body - update
   */
  managerUpdateSettingResponse(body?: Setting[]): Observable<HttpResponse<Setting>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "PATCH",
      this.rootUrl + `/manager/setting`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Setting = null;
        _body = _resp.body as Setting
        return _resp.clone({body: _body}) as HttpResponse<Setting>;
      })
    );
  }

  /**
   * @param body - update
   */
  managerUpdateSetting(body?: Setting[]): Observable<Setting> {
    return this.managerUpdateSettingResponse(body).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param key - undefined
   */
  managerSettingByKeyResponse(key: string): Observable<HttpResponse<Setting>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/manager/setting/byKey/${key}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Setting = null;
        _body = _resp.body as Setting
        return _resp.clone({body: _body}) as HttpResponse<Setting>;
      })
    );
  }

  /**
   * @param key - undefined
   */
  managerSettingByKey(key: string): Observable<Setting> {
    return this.managerSettingByKeyResponse(key).pipe(
      map(_r => _r.body)
    );
  }}

export module ManagerSettingsService {
}
