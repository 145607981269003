/* tslint:disable */

/**
 */
export class UserForm {
    id?: number;
    role_id?: number;
    company_id?: number;
    device_id?: number;
    email?: string;
    first_name?: string;
    last_name?: string;
    password?: string;
    address?: string;
    postal_code?: string;
    city?: string;
    register_code?: string;
    deve_ui?: string;
    appe_ui?: string;
    app_key?: string;
    confirm_password?: string;
    confirmed?: boolean;
}
